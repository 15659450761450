<template>
    <MainPage :title="config.app_name" :noback="true" :nomore="true" >
        <div slot="content">
            <div class="main-index main-bg2">
                <cube-scroll  ref="scroll" >
<!--                            <div style="line-height: 25px;font-size: 14px;">
                                <Swiper :options="swiperOptionBsm" >
                                  <SwiperSlide v-for="(slide, index) in this.bm.sp1" :key="index" >
                                    {{slide}}
                                    </SwiperSlide>
                                </Swiper>
                            </div> -->
<!--                   <Swiper :options="swiperOptionBanner" >
                        <SwiperSlide>
                            <img class="main-banner" src="/images/banner/slide-1.jpg">
                        </SwiperSlide>
                        <SwiperSlide>
                            <img class="main-banner" src="/images/banner/slide-2.jpg">
                        </SwiperSlide>
                        <SwiperSlide>
                            <img class="main-banner" src="/images/banner/slide-3.jpg">
                        </SwiperSlide>
                        <SwiperSlide>
                            <img class="main-banner" src="/images/banner/slide-4.jpg">
                        </SwiperSlide>
                    </Swiper> -->
                    <div v-if="this.videoUrl != ''" v-html="this.videoUrl" style="height: 323px;"></div>
                    <div >
                        <div class="main-Bulletin">
                            <i class="fas fa-bullhorn icon-bulletin"></i>
                            <Swiper :options="swiperOptionBulletin" >
                              <SwiperSlide v-for="(slide, index) in swiperBulletin" :key="index" @click.native="onItemClick(index)">
                                {{slide}}
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                   <div class="padding">
                        <cube-button  @click="go2page('/login')" >{{$t('lang.msg9') /*登錄*/}}</cube-button>
                    </div>
                   <div class="padding">
                        <cube-button  @click="go2page('/reg')" >{{$t('lang.msg10') /*註冊*/}}</cube-button>
                    </div>
                </cube-scroll>
            </div>
            <transition name="gamemenu">
              <SideBar v-if="side_bar"  v-on:callback="switchBar" ></SideBar>
            </transition>
            <div v-show="showDetail" >
                <div class="news-layout">
                    <div style="text-align: end;"><span class="markx"><i class="fa-solid fa-xmark" @click="closeClick"></i></span></div>
                    <cube-scroll  ref="scroll2" >
                        <div style="margin-top: 10px;font-size: 16px;line-height: 20px; padding: 0px 15px;">
                            {{Detail.Title}}
                        </div>
                        <!-- <div v-html="Detail.Content" style="margin-top: 10px;font-size: 16px;line-height: 20px;"></div> -->
                       <div style="margin-top: 10px;margin-bottom: 10px;;font-size: 16px;line-height: 20px; padding: 0px 15px;">
                            <pre>{{Detail.Content}}</pre>
                        </div>
                    </cube-scroll>
                </div>
                <div class="keyboard_block" ></div>
            </div>
        </div>
    </MainPage>
</template>
<script>
import SideBar from '@/components/side-bar.vue';
import { Locale } from 'cube-ui'
import MainPage from '@/components/nav-bar.vue'
import { apiBulletin,apiVideoLast,apiVendorList } from "@/api.js"
import { mapGetters } from "vuex"
import { globalMixin } from '@/mixin.js'
import { Swiper, SwiperSlide, } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'

export default {
    mixins: [globalMixin,Locale.localeMixin],
    data() {
        return {
            showDetail:false,
            Detail:{
                Title:'',
                Content:'',
            },
            content:{
              s1:false,  
              s2:false,  
              s3:false,  
              s4:false,  
              s5:false,  
              s6:false,  
              s7:false,  
              s8:false,  
            },
            side_bar:false,
            videoUrl:'',
            swiperBulletin:[],
            results:[],
            swiperOptionBulletin: {
                autoplay: {
                  delay: 3000,
                  disableOnInteraction: false
                },
            },
            swiperOptionBanner: {
                autoplay: {
                  delay: 8000,
                  disableOnInteraction: false
                },
            },
            swiperOptionBsm: {
                autoplay: {
                  delay: 3000,
                  disableOnInteraction: false
                },
                speed: 3000,
                loop: true,
            },            
        }
    },
    methods: {
        // chgcontext(v){
        //     v = !v
        //     return
        // },
        switchBar()
        {
            this.side_bar=!this.side_bar;
        },
        back()
        {
          this.$router.back()
        },
        closeClick(){
            this.showDetail=0;
        },
        onItemClick(index)
        {
            //this.dialog('alert',this.results[index].Title,this.results[index].Content);
            this.showDetail=1;
            this.Detail.Title = this.results[index].Title
            this.Detail.Content = this.results[index].Content
            setTimeout(() => {
                this.$refs.scroll2.refresh()
            }, 500);
        },
        // bulletinPopup(index)
        // {
        //   this.dialog('alert',this.results[index].Title,this.results[index].Content);
        // },
        getVideo()
        {
            apiVideoLast({
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status && response.data.data.length > 0)
                    {
                        this.videoUrl = response.data.data[0].Url
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
        getVendor()
        {
            apiVendorList({
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status && response.data.data.length > 0)
                    {
                        this.$store.dispatch('setVendor',response.data.data);
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
        getLog(page)
        {
            apiBulletin({
                page: page,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        if(response.data.data == null || response.data.data.length==0)
                        {
                            return;
                        }
                        for(var k in response.data.data)
                        {
                          this.swiperBulletin.push(response.data.data[k].Title);
                        }
                        this.results=this.results.concat(response.data.data);
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
    },
    computed: {
		...mapGetters({
            config: "config",
        }),
    },
    components: {
        MainPage,
        Swiper,
        SwiperSlide,
        SideBar,
    },
    created() 
    {
        this.getLog(1)
        this.getVideo()
        this.getVendor()
        //this.bm.sp1 = this.getBonusMsg1()
        // this.bm.sp2 = this.getBonusMsg2()
        // this.bm.sp3 = this.getBonusMsg3()     
        // this.cd1 = setInterval(() => {
        //     this.bm.sp2 = this.getBonusMsg2()
        // }, 7000);
        // this.cd2 = setInterval(() => {
        //     this.bm.sp3 = this.getBonusMsg3()
        // }, 12000);
        //console.log(this.bm)
    },
    destroyed: function()
	{
        // clearInterval(this.cd1);
        // clearInterval(this.cd2);
    } 
}

</script>
<style lang="stylus" rel="stylesheet/stylus" scoped>
.main-index
    // background-image: url("/images/home-bg.jpg")
    // //background-image: url("assets/images/index-bg.jpg")
    // background-size: cover;
    // background-position: inherit;
    // background-repeat: no-repeat;
    .main-title
        text-align: center;
        padding: 30px 30px 10px 30px;
        font-size: 20px;
    .main-info
        margin 10px 0px 10px 0px
    .main-info-img
        height 135px
        width 100%
    .main-border-bottom
        height 3px
        background-color $main-border-color
        width 60px
        margin auto
        margin-top 10px
    .main-bg
        // background-color: rgba(54,59,65,0.651);
    .main-block
        background-color: $main-block-bg-color;
    .main-block-content
        padding: 30px 30px 10px 30px;
        line-height: 20px;
    .main-block-content-white
        margin 20px 40px 0px 40px;
        padding: 15px;
        line-height: 20px;
        background-color $main-block-white-bg-color
        color $main-block-white-font-color
    .main-block-list
        background-color: #000;
        color: #e4bb7b;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        font-size: 20px;
        line-height: 30px;
        display: inline-block;
    .main-Bulletin
        display flex;
        background-color $navbar-bg-color;
        height: 30px;
        line-height 30px;
        margin-top: -3px;
    .icon-bulletin
        line-height 30px;
        padding 0px 10px;
    .index-introduction
        margin-top -3px;
        padding 15px 15px 10px 15px;
        line-height 20px;
        background-color: $index-bg2-color
        font-size: 12px;
        // border 1px #888 solid;
        // border-radius 5px;
        //overflow hidden;
        // position: fixed; 
        // top: 210px;
        // bottom: 80px;
        color $font-white-color
        .li-break
            margin-bottom 16px;
    .home-btn
        position: fixed; 
        bottom: 20px;
        right: 10px;
        left: 10px;
    .bmsty1
        font-size: 14px;
        margin-top -160px
    .bmsty2
        font-size: 14px;
        margin-top 30px
    .bmsty3
        font-size: 14px;
        margin-top 30px
</style>
