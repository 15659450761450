<template>
    <div class="side-bar">
        <cube-scroll  ref="scroll" >
            <ul class="side-body">
                <li @click.stop='click(7)' v-if="user.Username!='' && user.Username" >
                    <div>{{$t('lang.msg95') /*會員中心*/}}</div>
                    <!--
                    <div>{{user.username}} <i class="fas fa-chess-queen"></i></div>
                    <div>${{user.coin | numFilter}}</div>
                    -->
                </li>
<!--                <li v-if="user.username!=''" class="side-info">
                    <div @click.stop="getCoin()" >${{user.coin | numFilter}} <i class="fas fa-sync-alt" ></i></div>
                </li> -->
                <li  @click.stop='click(6)' v-show="user.Username!='' && user.Username"><div>{{$t('lang.msg171') /*交易*/}}</div></li>
                <li v-if="user.Username=='' || !user.Username"  @click.stop='click(1)'><div>{{$t('lang.msg9') /*登錄*/}}</div></li>
                <li v-if="user.Username=='' || !user.Username" @click.stop='click(2)'><div>{{$t('lang.msg10') /*註冊*/}}</div></li>
                <!-- <li  @click.stop='click(3)'><div>{{$t('lang.msg137') /*系统公告*/}}</div></li> -->
                <li  @click.stop='click(4)'><div>{{$t('lang.msg165') /*新聞中心*/}}</div></li>
                <li  @click.stop='click(5)'><div>{{$t('lang.msg166') /*視頻中心*/}}</div></li>
                <li  @click.stop='click(8)'><div>{{$t('lang.msg163') /*客服*/}}</div></li>
                <li  @click.stop='logout()'><div>{{$t('lang.msg60') /*登出*/}}</div></li>
<!--                <li  @click.stop='AndroidDown()'>
                    <div style="display: flex;justify-content: space-evenly;">
                        <div style="font-size: 35px;" @click.stop='AndroidDown()'>
                            <i class="fa-brands fa-google-pay" ></i>
                        </div>
                        <div>|</div>
                        <div style="font-size: 25px;" @click.stop='IOSDown()'>
                            <i class="fa-brands fa-apple" ></i>
                        </div>
                    </div>
                </li> -->
                <li  @click.stop='AndroidDown()'><div>{{$t('lang.msg186') /*Android APP*/}}</div></li>
                <li  @click.stop='IOSDown()'><div>{{$t('lang.msg187') /*IOS APP*/}}</div></li>

                                
<!--                <li  @click.stop='AndroidDown()'><div>
                    <i class="fa-brands fa-google-pay" style="font-size: 20px;"></i> {{$t('lang.msg186') /*客服*/}}
                </div></li> -->
                <!-- <li  @click.stop='IOSDown()'><div><i class="fa-brands fa-apple" style="font-size: 20px;"></i> {{$t('lang.msg187') /*客服*/}}</div></li> -->
                <!-- <li  @click.stop='click(1)'><div>{{$t('商品說明')}}</div></li> -->
                <!-- <li  @click.stop='click(2)'><div>{{$t('下單紀錄')}}</div></li> -->
                <!-- <li  @click.stop='click(3)'><div>開獎紀錄</div></li>
                <li  @click.stop='click(4)'><div>冷熱遺漏</div></li>
                <li  @click.stop='click(5)' v-if="options.group_type!=12 && options.group_type!=15 && options.group_type!=17"><div>長龍走勢</div></li> -->
                <li  @click.stop='setCookie("en")' ><div>English</div></li>
                <li  @click.stop='setCookie("vi-VN")' ><div>Tiếng Việt</div></li>
                <li  @click.stop='setCookie("zh-cn")' ><div>简体中文</div></li>
                <li  @click.stop='setCookie("zh-TW")' ><div>繁体中文</div></li>
            </ul>
        </cube-scroll>
        <div class="keyboard_block" @click.stop='hide()'></div>
    </div>
</template>

<script type="text/ecmascript-6">
import { mapGetters } from "vuex";
import { globalMixin } from '@/mixin.js';
import { Locale } from 'cube-ui'
import { apiLogout } from "@/api.js";
export default {
    mixins: [globalMixin,Locale.localeMixin],
    data() {
        return {
            showme:false,
            coin:'10',
        }
    },  
    props:{
        options: {
            type: Object,
            default() {
            return {}
            }
        }
    },
    computed: {
		...mapGetters({
            user: "user",
            config: 'config',
        }),
    },    
    methods: {
        logout()
        {
            apiLogout({
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.toast(
                            this.$t('lang.msg139'),
                            {
                                func: () => {
                                    this.$store.dispatch('clearUserInfo');
                                    //this.$router.push("/");
                                    this.$router.push("/login");
                                }
                            });
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
        getCoin(){
            this.$store.dispatch('getCoin');
        },
        hide(){
            this.$emit('callback',0);
        },
        click(num){
            switch(num)
            {
                case 1:
                    this.go2page('/login')
                    //this.$emit('switchView',"rule");
                    // var page="/rule/"+this.options.model+"/"+this.options.type_name;
                    // this.$router.push(page);
                    break;
                case 2:
                    this.go2page('/reg')
                    //this.$router.push("/user/betlog");
                    break;
                case 3:
                    //this.$emit('switchView',"codelist");
                    //this.$router.push("/game/codelist/"+this.options.group_type+"/"+this.options.type_id);
                    break;
                case 4:
                    this.go2page('/news')
                    //this.$emit('switchView',"hotlost");
                    //this.$router.push("/game/hotlost/"+this.options.group_type+"/"+this.options.type_id);
                    break;
                case 5:
                    this.go2page('/video')
                    //this.$emit('switchView',"clong");
                    //this.$router.push("/game/clong/"+this.options.group_type+"/"+this.options.type_id);
                    break;
                case 6:
                    this.go2page('/game')
                    break;
                case 7:
                    this.go2page('/user/index')
                    break;
                case 8:
                    this.service()
                    break;

            }
        },
    },

}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
.side-bar
    position: fixed; 
    bottom: 0px;
    top: 44px;
    right: 0px;
    //left: 0px;
    overflow:hidden;
    z-index: 10000;
    background-color : #121618f0;
    box-shadow: 0 1px 6px #ccc;
    ul
        list-style: none;
    ul>li
        width: 145px;
        text-align: center;
        line-height: 35px;
        font-size: 1rem;
        border-bottom: 1px #666 solid;
        padding: 3px;
        color: $font-white-color
        font-size: 14px;
    .keyboard_block
        position: fixed;
        bottom: 0;
        top: 0;
        left: 0;
        width: 100%;
        z-index: -1;
    .side-body
        z-index: 10000;
        .side-info
            font-size: 0.85rem;
            line-height: 24px;
            display: block;
        .head-bar
            line-height: 20px;        
            .side-log
                font-size: 1.3rem;
                border-radius: 50%;
                padding: 5px;
                background-color: #4a90e2;
                color: #fff;
                border: 2px solid #fff;
                width: 29px;
                margin: auto;
    .user-icon
        color $user-icon-color;
</style>
